import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import React, { useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { getEncodedActAsProspectDTOString } from "../util/ActAsProspectUtil";
import AuthClient from "./AuthClient";

interface HttpInterceptorProps {
  children: React.ReactNode;
}

const HttpInterceptor: React.FC<HttpInterceptorProps> = ({ children }) => {
  const { refresh, getAccessToken } = useAuth();

  const createInterceptors = () => {
    axiosRetry(AuthClient, {
      retries: 3, //attempts to retry
      retryCondition: (error) => {
        console.error("Error fetching from API will retry...");
        return !!error?.response && error.response.status === 401;
      }, // only retry on unauthenticated errors to prevent retrying 500s unless that is wanted
      retryDelay: (retryCount) => retryCount * 1000, // wait 1 second between retrys
    });

    AuthClient.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        // Do something before request is sent
        const accessToken = getAccessToken();
        if (accessToken) {
          config.headers = config.headers ?? {};
          config.headers.Authorization = `Bearer ${getAccessToken()}`;
          const actAsProspect = getEncodedActAsProspectDTOString();
          config.headers["act-as-prospect"] = actAsProspect ? actAsProspect : "";
        }
        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      },
    );

    AuthClient.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      (error: AxiosError) => {
        if (error.response?.status === 401) {
          if (error.response.data.message === "token_expired") {
            console.log("token expired, refreshing");
            refresh();
          }
        }
        return Promise.reject(error);
      },
    );
  };

  useEffect(() => {
    createInterceptors();
  }, []);

  return <>{children}</>;
};

export default HttpInterceptor;
