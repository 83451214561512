import { ActAsProspectDTO, ActAsProspectLocalStorageConstants } from "../types/Prospect";
import { getItem, removeItem } from "./LocalStorageUtil";

export const getActAsProspectId = (): number | null => {
  const dto = getParsedActAsProspectDTO();
  if (!dto) {
    return null;
  }
  return dto.prospectId;
};

export const getActAsProspectRedirectUrl = (): string | null => {
  const dto = getParsedActAsProspectDTO();
  if (!dto) {
    return null;
  }
  return dto.redirectUrl;
};

export const clearActAsProspect = (): void => {
  removeItem(ActAsProspectLocalStorageConstants.ACT_AS_PROSPECT_DTO);
  removeItem(ActAsProspectLocalStorageConstants.ACT_AS_PROSPECT_ACCESS_TOKEN);
};

export const getParsedActAsProspectDTO = (): ActAsProspectDTO | null => {
  const decodedDTO = getEncodedActAsProspectDTOString();
  if (!decodedDTO) {
    return null;
  }

  return JSON.parse(window.atob(decodedDTO));
};

export const getEncodedActAsProspectDTOString = (): string | null => {
  const encodedDto = getItem(ActAsProspectLocalStorageConstants.ACT_AS_PROSPECT_DTO) as string;
  if (!encodedDto) {
    return null;
  }
  return encodedDto;
};
