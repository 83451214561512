import React, { useEffect, useState } from "react";
import { ProfileRouteConstants } from "../constants/ProfileConstants";
import RouteConstants, { RouteConstantsType } from "../constants/RouteConstants";
import { PROSPECT_PROFILE_TYPE_IDS } from "../types/Prospect";
import { useAuth } from "./AuthContext";

type VisibilityContextType = {
  profileTypeId: number | undefined;
  canViewBaseballSchedule: boolean;
  canViewShowcaseData: boolean;
  canProfileTypeViewRoute: (route: RouteConstantsType | ProfileRouteConstants) => boolean;
};

const routeProfileTypeIdMap = new Map([
  [RouteConstants.MEDIA_UPLOADS, new Set([PROSPECT_PROFILE_TYPE_IDS.DRAFT, PROSPECT_PROFILE_TYPE_IDS.SHOWCASE])],
  [RouteConstants.BASEBALL_SCHEDULE, new Set([PROSPECT_PROFILE_TYPE_IDS.DRAFT])],
  [RouteConstants.RESOURCES, new Set([PROSPECT_PROFILE_TYPE_IDS.DRAFT, PROSPECT_PROFILE_TYPE_IDS.SHOWCASE])],
  [
    RouteConstants.SHOWCASE_DATA,
    new Set([
      PROSPECT_PROFILE_TYPE_IDS.DRAFT,
      PROSPECT_PROFILE_TYPE_IDS.SHOWCASE,
      PROSPECT_PROFILE_TYPE_IDS.MEDICAL_STUDY,
    ]),
  ],
  [RouteConstants.NAME_PRONUNCIATION, new Set([PROSPECT_PROFILE_TYPE_IDS.DRAFT])],
  [RouteConstants.TRANSCRIPTS, new Set([PROSPECT_PROFILE_TYPE_IDS.DRAFT])],
  [RouteConstants.VISION_ASSESSMENT_FORM, new Set([PROSPECT_PROFILE_TYPE_IDS.DRAFT])],
  [RouteConstants.MEDICAL, new Set([PROSPECT_PROFILE_TYPE_IDS.DRAFT])],
  [
    ProfileRouteConstants.SCHOOL_HISTORY,
    new Set([PROSPECT_PROFILE_TYPE_IDS.DRAFT, PROSPECT_PROFILE_TYPE_IDS.SHOWCASE]),
  ],
  [ProfileRouteConstants.DRAFT_ADVISOR, new Set([PROSPECT_PROFILE_TYPE_IDS.DRAFT, PROSPECT_PROFILE_TYPE_IDS.SHOWCASE])],
  [ProfileRouteConstants.SUMMER_LEAGUES, new Set([PROSPECT_PROFILE_TYPE_IDS.DRAFT])],
  [
    ProfileRouteConstants.ADDITIONAL_CONTACTS,
    new Set([PROSPECT_PROFILE_TYPE_IDS.DRAFT, PROSPECT_PROFILE_TYPE_IDS.SHOWCASE]),
  ],
  [ProfileRouteConstants.FAMILY_INFORMATION, new Set([PROSPECT_PROFILE_TYPE_IDS.DRAFT])],
]);

const VisibilityContext = React.createContext<VisibilityContextType | undefined>(undefined);

const VisibilityProvider: React.FC = ({ ...props }) => {
  const { user, asUser } = useAuth();
  const [profileTypeId, setProfileTypeId] = useState<number>();
  const [canViewBaseballSchedule, setCanViewBaseballSchedule] = useState(false);
  const [canViewShowcaseData, setCanViewShowcaseData] = useState(false);

  const canProfileTypeViewRoute = (route: RouteConstantsType | ProfileRouteConstants): boolean => {
    // only limit visibility if route is in the map
    if (!routeProfileTypeIdMap.has(route)) {
      return true;
    }
    if (profileTypeId) {
      return routeProfileTypeIdMap.get(route)?.has(profileTypeId) || false;
    }
    return false;
  };

  useEffect(() => {
    if (!!asUser) {
      setProfileTypeId(asUser?.profileTypeId);
    } else {
      setProfileTypeId(user?.profileTypeId);
    }
    // baseball schedule only visible for actual prospects not acting as prospect
    setCanViewBaseballSchedule(!!user?.canViewBaseballSchedule || !!asUser?.canViewBaseballSchedule);
    setCanViewShowcaseData(!!user?.canViewShowcaseData || !!asUser?.hasEvent);
  }, [user, asUser]);

  return (
    <VisibilityContext.Provider
      value={{
        profileTypeId,
        canViewBaseballSchedule,
        canViewShowcaseData,
        canProfileTypeViewRoute,
      }}
      {...props}
    />
  );
};

const useVisibility = (): VisibilityContextType => {
  const context: VisibilityContextType | undefined = React.useContext(VisibilityContext);
  if (context === undefined) {
    throw new Error(`useVisibility must be used within a VisibilityProvider`);
  }
  return context;
};

export { useVisibility, VisibilityProvider };
