type BuilderAccess = {
  hasAbleToAccessQuestionnaire: boolean;
  hasAbleToCreateQuestionnaire: boolean;
  hasAbleToReviewQuestionnaire: boolean;
};

export type UserDetails = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  userRole: string[];
  orgCode: string;
  orgId: number;
  orgName: string;
  builderAccess: BuilderAccess;
  stale: boolean;
  onboarding: boolean;
  firstLogin: boolean;
  hasMedicalViewAccess: boolean;
  hasMedicalUploadAccess: boolean;
  canViewBaseballSchedule: boolean;
  canViewShowcaseData: boolean;
  preferredLanguageId?: number;
  archived: boolean;
  requiresReturningProspectModal: boolean;
  notificationUuid?: string;
  profileTypeId: number;
};

export type AppViewUrlResponse = {
  url: string;
};

export const UserRoles = {
  PROSPECT_BOC_ADMIN: "prospect-boc-admin",
};
